html,
body,
#root {
	width: 100%;
	height: 100%;
	margin: 0;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	background-color: hsl(220deg 5% 12%);
	color: white;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

[placeholder]:empty::before {
	content: attr(placeholder);
	color: #fff6;
}

/* Scrollbar */

::-webkit-scrollbar {
	width: 7px;

	background-color: #fff1;
}

::-webkit-scrollbar-thumb {
	background-color: #fff2;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #fff3;
}

::-webkit-scrollbar-thumb:active {
	background-color: #ffffff16;
}