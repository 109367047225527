.notesApp {
	position: relative;
	width: 100%;
	min-height: calc(100% - 51px);

	display: flex;
	justify-content: center;
}


/* Header */

.appHeader {
	position: relative;
	height: 50px;
	padding: 0 20px;

	background-color: #fff1;
	border-bottom: solid 1px #fff4;

	display: flex;
	align-items: center;
	justify-content: space-between;
}

.appHeader>h1 {
	margin: 0;

	font-size: 22px;
}

.createNote {
	position: absolute;
	width: 300px;
	height: 35px;
	top: 7px;
	left: calc(50% - 150px);

	background-color: hsl(220deg 5% 12%);
	border: solid 1px hsl(220deg 5% 35%);
	border-radius: 5px;
	cursor: text;
	z-index: 1;

	transition: transform .2s, width .2s, height .2s, opacity .2s, z-index 0s .2s;
}

.createNote>p {
	height: 35px;
	margin: 0;
	margin-left: 10px;

	line-height: 35px;
	color: #fff8;
}

.createNoteHide {
	/* width: 549px; */
	width: 528px;
	max-width: unset;
	opacity: 0;
	visibility: hidden;
	z-index: 2;

	transition: transform .2s, width .2s, height .2s, opacity .2s, visibility 0s .2s;
}

@media screen and (max-width: 740px) {
	.appHeader>h1 {
		display: none;
	}
}

/* Notes */

.notes {
	position: relative;
	padding: 20px;
}

.note {
	position: absolute;
	width: 250px;
	height: 120px;
	padding: 10px;
	margin: 5px;

	--accent-hue: 220deg;
	--accent-saturation: 5%;
	background-color: hsl(var(--accent-hue) var(--accent-saturation) 15%);
	border: solid 1px hsl(var(--accent-hue) var(--accent-saturation) 45%);
	border-radius: 5px;

	transition: visibility 0s .2s;
}

.noteClickable {
	cursor: pointer;
}

.noteClickable:hover {
	background-color: hsl(var(--accent-hue) var(--accent-saturation) 17%);
}

.noteClickable:active {
	background-color: hsl(var(--accent-hue) var(--accent-saturation) 12%);
}

.noteHide {
	visibility: hidden;
	transition: transform .2s ease;
}

.noteHeader {
	margin-bottom: 10px;

	flex-shrink: 0;
	overflow: hidden;
}

.noteHeader>h1 {
	margin: 0;

	font-size: 18px;
	overflow-wrap: break-word;
	white-space: pre-wrap;

	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.noteContent {
	overflow: hidden;
}

.noteContent>p {
	margin: 0;

	font-size: 16px;
	color: #eee;
	overflow-wrap: break-word;
	white-space: pre-wrap;

	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
}

/* Note modal */

.noteModal {
	position: absolute;
	width: 100%;
	height: calc(100% + 50px);
	top: -50px;
	left: 0;

	background-color: transparent;
	z-index: -1;

	transition: background-color .2s, transform .2s, z-index 0s .2s;
}

.noteModalShow {
	background-color: #0008;
	z-index: 1;

	transition: background-color .2s;
}

.editNote {
	position: absolute;
	margin: 5px;

	--accent-hue: 220deg;
	--accent-saturation: 5%;
	background-color: hsl(var(--accent-hue) var(--accent-saturation) 15%);
	border: solid 1px hsl(var(--accent-hue) var(--accent-saturation) 45%);
	border-radius: 5px;
	overflow: hidden;
	opacity: 0;

	transition: width .2s, height .2s, transform .2s, opacity 0s .2s;

	display: flex;
	flex-direction: column;
}

.noteModalShow .editNote {
	opacity: 1;

	transition: width .2s, height .2s, transform .2s;
}

.editNoteContentWrapper {
	height: 100%;
	padding: 10px;

	overflow-y: scroll;

	display: flex;
	flex-direction: column;
}

.noteModalShow .editNote::-webkit-scrollbar {
	opacity: 1;
}

.editNote h1 {
	width: 100%;
	margin: 0;

	outline: none;

	display: inline-block;
}

.editNote p {
	width: 100%;
	height: 100%;
	margin: 0;

	outline: none;

	display: inline-block;
}

.editNote .noteContent {
	overflow: unset;
	flex-grow: 1;
}

.noteEdited {
	height: 20px;

	opacity: 0;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;

	transition: opacity .2s;
}

.noteEdited>p {
	width: fit-content;

	color: #fff8;
	font-size: 13px;
}

.noteModalShow .noteEdited {
	opacity: 1;
}

.editNoteActions {
	position: relative;
	height: 36px;

	background-color: hsl(var(--accent-hue) var(--accent-saturation) 20%);
	border-radius: 0 0 5px 5px;
	box-shadow: 0 -3px 15px #0004;
	flex-shrink: 0;
	opacity: 0;

	transition: background-color .15s, opacity .2s;
}

.noteModalShow .editNoteActions {
	opacity: 1;
}

.actionsPage {
	position: absolute;
	width: calc(100% - 16px);
	height: calc(100% - 16px);
	top: 0;
	left: 0;
	padding: 8px;

	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 5px;

	transition: transform .15s, opacity .15s;
}

.actionsPageHide {
	transform: translateX(20px);
	opacity: 0;
	visibility: hidden;

	transition: transform .15s, opacity .15s, visibility 0s .15s;
}

.actionsPageHide:first-of-type {
	transform: translateX(-20px);
}

.actionsPage>* {
	flex-shrink: 0;
}

.actionsPage h1 {
	color: #fffa;
	font-size: 14px;
	flex-shrink: unset;
}

.changeColorAction {
	width: 18px;
	height: 18px;

	background-color: hsl(var(--accent-hue) var(--accent-saturation) 50%);
	border-radius: 10px;
	border: solid 1px #fff5;
	cursor: pointer;
}

.changeColorAction:hover {
	border: solid 1px #fff8;
}

.changeColorAction:active {
	background-color: hsl(var(--accent-hue) var(--accent-saturation) 40%);
	border: solid 1px #fff4;
}

.deleteAction {
	fill: #fffa;
	cursor: pointer;
}

.deleteAction:hover {
	fill: #fffc;
}

.deleteAction:active {
	fill: #fff8;
}

.deleteActionConfirm {
	fill: #faaa;
}

.deleteActionConfirm:hover {
	fill: #faac;
}

.deleteActionConfirm:active {
	fill: #faa8;
}